/*-------------------------------------------------------*/
/* Typography
/*-------------------------------------------------------*/

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

h1,h2,h3,h4,h5,h6 {
  font-family: $heading-font;
  margin-top: 0;
  margin-bottom: 10px;
  color: $heading-color;
  font-weight: 600;
  line-height: 1.3;
  letter-spacing: -0.5px;
}

h1 { font-size: 32px; letter-spacing: -0.03em; }
h2 { font-size: 26px; }
h3 { font-size: 23px; }
h4 { font-size: 20px; }
h5 { font-size: 18px; }
h6 { font-size: 16px; }

@include bp-sm-down {
  h1 { font-size: 26px; }
  h2 { font-size: 22px; }
  h3 { font-size: 18px; }
}

@media (max-width: 640px) {

  h1 { font-size: 24px; }
  h2 { font-size: 20px; }
  h3 { font-size: 19px; }
  h4 { font-size: 18px; }

}

h1 > a, h2 > a, h3 > a, h4 > a, h5 > a, h6 > a {
  color: inherit;
}

p {
  font-size: 16px;
  color: $text-color;
  font-weight: normal;
  line-height: 26px;
  margin: 0 0 10px;
}

address {
  font-style: normal;
  margin-bottom: 0;
  font-size: 16px;
}

.lead {
  font-size: 18px;
  line-height: 30px;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

@include bp-xl-down { .text-lg-center {text-align: center}; }
@include bp-lg-down { .text-md-center {text-align: center}; }
@include bp-md-down { .text-sm-center {text-align: center}; }
@include bp-sm-down { .text-xs-center {text-align: center}; }

blockquote {
  text-align: center;
  margin-bottom: 40px;
  margin-top: 40px;

  p {
    font-size: 32px;
    line-height: 1.3;
    margin-bottom: 0 !important; 
    position: relative;
    color: $heading-color;
  }

  cite {
    font-size: 15px;
    font-weight: 400;
    color: $meta-color;
  }
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid $border-color;
}
