// Colors
$main-color: #2D95E3;
$main-color-violet: #3E3EDD;
$text-color: #54555E;
$meta-color: #83858F;
$heading-color: #171821;
$nav-color: #171821;
$bg-light: #f7f7f7;
$bg-dark: #171821;
$border-color: #E3E4E8;


// Fonts
$body-font: 'Source Sans Pro', sans-serif;
$heading-font: 'Montserrat', sans-serif;
$font-roboto: 'Roboto', sans-serif;
$font-barlow: 'Barlow', sans-serif;
$font-lora: 'Lora', serif;
$font-poppins: 'Poppins', sans-serif;
$font-rubik: 'Rubik', sans-serif;


// Navbar
$nav-height: 48px;