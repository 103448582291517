/*-------------------------------------------------------*/
/* Grid
/*-------------------------------------------------------*/

.section {
  margin-bottom: 40px;
}

@media (min-width: 1280px) {
  .container {
    max-width: 1248px;
  }
}

.container-semi-fluid {
  padding: 0 50px;

  @include bp-md-down {
    padding: 0 15px;
  }
}

/* Flexbox
-------------------------------------------------------*/

.flex-parent {
  @include display-flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.flex-child {
  @include flex (1 0 0);
}


/* Columns With No Gutters
-------------------------------------------------------*/
.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}


/* Row Gutters
-------------------------------------------------------*/
.row-2 {
  margin: 0 -1px;

  & > div,
  & > aside {
    padding: 0 1px;
  }
}

.row-8 {
  margin: 0 -4px;

  & > div,
  & > aside {
    padding: 0 4px;
  }
}

.row-10 {
  margin: 0 -5px;

  & > div,
  & > aside {
    padding: 0 5px;
  }
}

.row-16 {
  margin: 0 -8px;

  & > div,
  & > aside {
    padding: 0 8px;
  }
}

.row-20 {
  margin: 0 -10px;

  & > div,
  & > aside {
    padding: 0 10px;
  }
}

.row-24 {
  margin: 0 -12px;

  & > div,
  & > aside {
    padding: 0 12px;
  }
}